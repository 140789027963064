.lead {
  @include font-size($lead-font-size);
  font-weight: $lead-font-weight;
}

//
// Emphasis
//
.small {
  @extend small;
}

.mark {
  @extend mark;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled();
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.38
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.5555;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.4285;
}