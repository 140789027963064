/*!
 * Bootstrap v5.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "functions";

$blue: #2693FF;
$green: #6CC395;
$red:  #F45F50;
$orange: #FCBC41;
$yellow:  #F2E150;
$purple: #9062F0;

$sky-bridge: #3CACD7;
$sky-clean: #50C1EC;
$black-panther: #293745;
$the-doctor: #F8FAFB;

$dark-1: $black-panther;
$dark-2: #2B3140;
$dark-3: #3F4759;
$dark-4: #586276;
$dark-5: #798297;
$dark-6: #949DB0;

$mid-1: #97989A;
$mid-2: #ABABAB;
$mid-3: #DADADA;
$mid-4: #E4E4E4;
$mid-5: #F3F3F3;
$mid-6: #FDFDFD;

$base-1: #7F859D;
$base-2: #A0A5B8;
$base-3: #BBD0E5;
$base-4: #DAE4EA;
$base-5: #E7EFF3;
$base-6: #EFF6FC;

$warning-1: #CF9627;
$warning-2: #E4A833;
$warning-4: #FECF73;
$warning-5: #FFE5B1;

$danger-1: #CA4438;
$danger-2: #E15144;
$danger-4: #FF7669;
$danger-5: #FFAEA7;

$success-1: #48906B;
$success-2: #5EAE84;
$success-4: #98DCB8;
$success-5: #CAF5DF;

$primary: $sky-bridge;
$secondary: $base-5;

$body-color: $black-panther;

$link-decoration: none;
$link-hover-decoration: none;
$link-hover-color: $sky-clean;

$line-height-base: 1.375;

// Create your own map
$custom-colors: (
    "body": $body-color,
    "sky-bridge": $sky-bridge,
    "sky-clean": $sky-clean,
    "black-panther": $black-panther,
    "the-doctor": $the-doctor,
    "dark-1": $dark-1,
    "dark-2": $dark-2,
    "dark-3": $dark-3,
    "dark-4": $dark-4,
    "dark-5": $dark-5,
    "dark-6": $dark-6,
    "base-1": $base-1,
    "base-2": $base-2,
    "base-3": $base-3,
    "base-4": $base-4,
    "base-5": $base-5,
    "base-6": $base-6,
    "mid-1": $mid-1,
    "mid-2": $mid-2,
    "mid-3": $mid-3,
    "mid-4": $mid-4,
    "mid-5": $mid-5,
    "mid-6": $mid-6,
    "warning-1": $warning-1,
    "warning-2": $warning-2,
    "warning-4": $warning-4,
    "warning-5": $warning-5,
    "success-1": $success-1,
    "success-2": $success-2,
    "success-4": $success-4,
    "success-5": $success-5,
    "danger-1": $danger-1,
    "danger-2": $danger-2,
    "danger-4": $danger-4,
    "danger-5": $danger-5
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    lg: 1024px,
    xl: 1600px
);

$container-max-widths: (
    lg: 1280px,
    xl: 1440px
);

$grid-gutter-width: 24px;

$font-family-base: 'Lato', sans-serif;
$font-weight-base: normal;

$headings-font-family: 'Montserrat', sans-serif;
$headings-font-weight: normal;
$headings-line-height: 1.3;

$h1-font-size: 3rem;
$h2-font-size: 2.75rem;
$h3-font-size: 2.125rem;
$h4-font-size: 1.75rem;
$h5-font-size: 1.5rem;
$h6-font-size: 1.25rem;

$lead-font-size: 20px;
$lead-font-weight: normal;

$navbar-padding-y: 0;
$navbar-brand-margin-end: 0;
$nav-link-padding-y: 0;
$nav-link-padding-x: 0;

$btn-padding-y: 18px;
$btn-padding-x: 36px;
$btn-font-size: 1.25rem;
$btn-line-height: 1.2;
$btn-border-radius: 6px;
$btn-font-weight: 700;
$btn-box-shadow: none;

$disabled-border: $mid-5;
$disabled-background: $mid-5;
$disabled-color: $mid-1;

$border-radius: 6px;

@import "variables";
@import "mixins";
@import "utilities";

$theme-colors: map-merge($theme-colors, $custom-colors);

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
//@import "tables";
//@import "forms";
@import "buttons";
//@import "transitions";
//@import "dropdown";
//@import "button-group";
//@import "nav";
//@import "navbar";
//@import "card";
//@import "accordion";
//@import "breadcrumb";
//@import "pagination";
//@import "badge";
//@import "alert";
//@import "progress";
//@import "list-group";
//@import "close";
//@import "toasts";
//@import "modal";
//@import "tooltip";
//@import "popover";
//@import "carousel";
//@import "spinners";
//@import "offcanvas";
//@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

.object-cover {
    object-fit: cover;
}

.mt-50 {
    margin-top: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.ms-50 {
    margin-left: 50px;
}

.me-50 {
    margin-right: 50px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ms-100 {
    margin-left: 100px;
}

.me-100 {
    margin-right: 100px;
}

.ps-50 {
    padding-left: 50px;
}

.pe-50 {
    padding-right: 50px;
}

.pt-50 {
    padding-top: 50px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.text-dark-1 {
    color: $dark-1;
}

.text-dark-2 {
    color: $dark-2;
}

.text-dark-3 {
    color: $dark-3;
}

.text-dark-4 {
    color: $dark-4;
}

.text-dark-5 {
    color: $dark-5;
}

.text-dark-6 {
    color: $dark-6;
}

.text-base-1 {
    color: $base-1;
}

.text-base-2 {
    color: $base-2;
}

.text-base-3 {
    color: $base-3;
}

.text-base-4 {
    color: $base-4;
}

.text-base-5 {
    color: $base-5;
}

.text-base-6 {
    color: $base-6;
}

.text-mid-1 {
    color: $mid-1;
}

.text-mid-2 {
    color: $mid-2;
}

.text-mid-3 {
    color: $mid-3;
}

.text-mid-4 {
    color: $mid-4;
}

.text-mid-5 {
    color: $mid-5;
}

.text-mid-6 {
    color: $mid-6;
}

.text-sky-bridge {
    color: $sky-bridge;
}

.text-sky-clean {
    color: $sky-clean;
}

.text-black-panther {
    color: $black-panther;
}

.text-the-doctor {
    color: $the-doctor;
}

.btn-primary {
    color: #fff;
}

.btn-primary:hover,
.btn-primary:focus {
    color: #fff;
    background-color: $sky-clean;
}

.btn-secondary {
    color: $dark-3;
}

.btn-secondary:hover {
    background-color: $white;
    color: $dark-5;
    border-color: $base-4;
}

.btn-secondary:focus,
.btn-secondary:active {
    background-color: $base-4;
    color: $dark-3;
}

.btn-link {
    padding: 0;
}

.btn-link:hover {
    color: $dark-3;
}

.btn-link:focus,
.btn-link:active {
    color: $dark-6;
}

.btn-link:disabled {
    color: $mid-1;
}